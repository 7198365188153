<template>
    <List
        :items="meetings"
        :headers="headers"
        @rowClicked="showMeeting"
        :sort-by="[{ key: 'Company', order: 'asc' }]"
        :loading="loading"
        :showButton="true"
    >
        <template #button="{ item }">
            <v-col cols="auto">
                <v-btn
                    class="button-bg-secondary"
                    :text="$t('meetingFollowup.modify')"
                    prepend-icon="mdi-database-outline"
                    @click.stop="showMeetingState(item)"
                />
            </v-col>
        </template>
    </List>
    <MeetingDetails
        :meetingProps="selectedMeeting"
        v-model="showDialog"
        @close="showDialog = false"
    />
    <MeetingFollowupPopup
        v-model="statePopup"
        :selectedMeetingState="selectedMeetingState"
        @close="statePopup = false"
    />
</template>

<script setup lang="ts">
import MeetingDetails from "@/components/MeetingDetails.vue";
import MeetingFollowupPopup from "@/components/MeetingFollowupPopup.vue";
import List from "@/components/customVuetify/List.vue";
import { DocumentData } from "firebase/firestore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t: $t } = useI18n();
const statePopup = ref(false);

defineProps({
    meetings: Array as () => DocumentData[],
    loading: Boolean,
});

const selectedMeetingState = ref({});
const selectedMeeting = ref({});
const showDialog = ref(false);

function showMeeting(meeting: any) {
    selectedMeeting.value = meeting;
    showDialog.value = true;
}

function showMeetingState(meeting: any) {
    selectedMeetingState.value = meeting;
    statePopup.value = true;
}

const headers = [
    { title: $t("extracts.type"), value: "Type" },
    { title: $t("extracts.company"), value: "Company" },
    { title: $t("extracts.contact"), value: "Contact" },
    { title: $t("extracts.date"), value: "Date" },
    { title: $t("extracts.hour"), value: "Hour" },
    { title: $t("meetingFollowup.meetingState"), value: "MeetingState" },
    {
        title: $t("meetingFollowup.prospectInterestForMeeting"),
        value: "ProspectInterestForMeeting",
    },
    { title: $t("meetingFollowup.need"), value: "Need" },
    { title: $t("meetingFollowup.state"), value: "State" },
    { title: $t("meetingFollowup.meetingComment"), value: "MeetingComment" },
];
</script>
