<template>
    <Dialog
        :title="$t('meetingFollowup.followupStateMeetingDetails')"
        width="75vw"
        :isLoading="isDataLoading"
        :isActions="false"
        @close="close"
    >
        <template #text>
            <v-row>
                <v-col cols="12" class="align-top">
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="meetingFollowup.meetingState"
                        v-model="followup.MeetingState"
                        :readonly="readonly"
                    />
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="meetingFollowup.prospectInterestForMeeting"
                        v-model="followup.ProspectInterestForMeeting"
                        :readonly="readonly"
                    />
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="meetingFollowup.need"
                        v-model="followup.Need"
                        :readonly="readonly"
                    />
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="meetingFollowup.state"
                        v-model="followup.State"
                        :readonly="readonly"
                    />
                    <CardTextField
                        noTopPadding
                        textArea
                        fieldSize="8"
                        label="meetingFollowup.meetingComment"
                        v-model="followup.MeetingComment"
                        :readonly="readonly"
                    />
                </v-col>
                <v-col class="text-end">
                    <v-btn
                        v-if="readonly"
                        class="button-bg-white"
                        @click="readonly = false"
                        prependIcon="mdi-pencil-outline"
                        :text="$t('common.modifyInfos')"
                    />
                    <v-btn
                        v-else
                        class="button-bg-secondary"
                        :loading="isLoading"
                        @click="updateData"
                        prependIcon="mdi-check"
                        :text="$t('common.validateModifications')"
                    />
                </v-col>
            </v-row>
        </template>
        <template #footer> </template>
    </Dialog>
</template>

<script setup lang="ts">
import Dialog from "@/components/customVuetify/Dialog.vue";
import { db } from "@/main";
import { defaultFollowUpData } from "@/types/call";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import CardTextField from "./customVuetify/CardTextField.vue";

const { t: $t } = useI18n();

const isLoading = ref(false);
const isDataLoading = ref(false);
const readonly = ref(true);
const emit = defineEmits(["close"]);

const props = defineProps({
    selectedMeetingState: {
        type: Object,
        required: true,
        default: () => ({}),
    },
});

const followup = ref<any>({ ...defaultFollowUpData });

async function fetchMeetingData(
    OrderId: string,
    ProspectId: string,
    DocId: string
) {
    const docRef = doc(
        db,
        "Orders",
        OrderId,
        "Prospects",
        ProspectId,
        "Calls",
        DocId,
        "FollowUp",
        "Meeting"
    );
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            followup.value = { ...defaultFollowUpData, ...docSnap.data() };
        } else {
            console.error("No such document!");
        }
    } catch (error) {
        console.error("Error fetching Firestore document:", error);
    }
}

watch(
    () => props.selectedMeetingState,
    (newVal) => {
        if (newVal && newVal.OrderId && newVal.ProspectId && newVal.DocId) {
            fetchMeetingData(newVal.OrderId, newVal.ProspectId, newVal.DocId);
        } else {
            console.warn("Invalid state or missing data on page load.");
        }
    },
    { immediate: true }
);

function close() {
    emit("close");
}

async function updateData() {
    isLoading.value = true;
    const docRef = doc(
        db,
        "Orders",
        props.selectedMeetingState.OrderId,
        "Prospects",
        props.selectedMeetingState.ProspectId,
        "Calls",
        props.selectedMeetingState.DocId,
        "FollowUp",
        "Meeting"
    );

    try {
        await setDoc(docRef, {
            MeetingState: followup.value.MeetingState,
            ProspectInterestForMeeting:
                followup.value.ProspectInterestForMeeting,
            Need: followup.value.Need,
            State: followup.value.State,
            MeetingComment: followup.value.MeetingComment,
        });
    } catch (error) {
        console.error("Error saving Firestore document:", error);
    } finally {
        isLoading.value = false;
        readonly.value = true;
    }
}
</script>

<style scoped>
.centered-title {
    text-align: center;
    margin-bottom: 1rem;
}

.v-row {
    height: 100% !important;
}

.v-col {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.align-top {
    align-items: flex-start;
}
</style>
