<template>
    <v-navigation-drawer
        v-model="navbarOpen"
        @input="emitUpdate"
        app
        temporary
        class="d-flex flex-column"
        width="250"
        :mini-variant="true"
        expand-on-hover
    >
        <v-col
            cols="auto"
            class="d-flex flex-column"
            style="height: 100%; padding-right: 0; overflow: hidden"
        >
            <v-list class="fill-height list">
                <v-list-item
                    link
                    density="compact"
                    :to="'/home'"
                    prepend-icon="mdi-home-outline"
                    :subtitle="$t('navbar.myDay')"
                />
                <v-divider />
                <v-list-item
                    density="compact"
                    class="title"
                    :title="$t('navbar.functional')"
                />
                <v-list-item
                    v-if="!isEmployee"
                    link
                    density="compact"
                    :to="'/users'"
                    prepend-icon="mdi-account-outline"
                    :subtitle="$t('navbar.users')"
                />
                <v-list-item
                    v-if="(isAdminUser || isYouscaleUser) && !isEmployee"
                    link
                    density="compact"
                    :to="'/teams'"
                    prepend-icon="mdi-account-group-outline"
                    :subtitle="$t('navbar.teams')"
                />
                <v-list-item
                    v-if="(isAdminUser || isYouscaleUser) && !isEmployee"
                    link
                    density="compact"
                    :to="'/clients'"
                    prepend-icon="mdi-account-multiple-outline"
                    :subtitle="$t('navbar.clients')"
                />
                <v-list-item
                    link
                    density="compact"
                    :to="'/orders'"
                    prepend-icon="mdi-clipboard-text-outline"
                    :subtitle="$t('navbar.orders')"
                />
                <v-list-item
                    link
                    density="compact"
                    :to="'/db'"
                    prepend-icon="mdi-database-outline"
                    :subtitle="$t('navbar.db')"
                />
                <v-list-item
                    link
                    density="compact"
                    :to="'/calls'"
                    prepend-icon="mdi-phone-outline"
                    :subtitle="$t('navbar.calls')"
                />
                <v-divider v-if="!isEmployee" />
                <v-list-item
                    v-if="!isEmployee"
                    density="compact"
                    :title="$t('navbar.reporting')"
                />
                <v-list-item
                    v-if="!isEmployee"
                    link
                    density="compact"
                    :to="'/performances'"
                    prepend-icon="mdi-chart-line"
                    :subtitle="$t('navbar.performances')"
                />
                <v-list-item
                    v-if="!isEmployee"
                    link
                    density="compact"
                    :to="'/extracts/meetings'"
                    prepend-icon="mdi-clipboard-text-outline"
                    :subtitle="$t('navbar.extracts')"
                />
                <v-list-item
                    v-if="!isEmployee"
                    link
                    density="compact"
                    :to="'/MeetingFollowup'"
                    prepend-icon="mdi-clipboard-text-outline"
                    :subtitle="$t('navbar.meetingFollowup')"
                />
                <v-spacer />
                <v-divider />
                <v-list-item
                    density="compact"
                    @click="searchProspect = !searchProspect"
                    prepend-icon="mdi-magnify"
                    :subtitle="$t('navbar.searchProspect')"
                />
                <v-list-item
                    density="compact"
                    @click="helpOpen = !helpOpen"
                    prepend-icon="mdi-help-circle-outline"
                    :subtitle="$t('navbar.help')"
                />
                <v-list-item
                    density="compact"
                    class="icon-spacing"
                    prepend-icon="mdi-lock-reset"
                    @click="changePasswordOpen = !changePasswordOpen"
                    :subtitle="$t('navbar.changePassword')"
                />
                <v-list-item
                    density="compact"
                    @click="logout"
                    prepend-icon="mdi-logout"
                    :subtitle="$t('navbar.logout')"
                />
            </v-list>
        </v-col>
        <SearchProspect
            v-model="searchProspect"
            @close="searchProspect = false"
        />
        <HelpPopup
            v-model="helpOpen"
            @success="snackbar($t('help.success'))"
            @close="helpOpen = false"
        />
        <ChangePassword
            v-model="changePasswordOpen"
            @success="snackbar($t('login.changePasswordSuccess'))"
            @close="changePasswordOpen = false"
        />
        <Snackbar v-model="showSnackbar" :text="snackbarText" />
    </v-navigation-drawer>
</template>

<script setup lang="ts">
import { getCurrentUser } from "@/commons/firebase";
import HelpPopup from "@/components/HelpPopup.vue";
import Snackbar from "@/components/SnackBar.vue";
import router from "@/router";
import { getAuth, getIdTokenResult, signOut } from "firebase/auth";
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";
import ChangePassword from "./ChangePassword.vue";
import SearchProspect from "./SearchProspect.vue";

const { t: $t } = useI18n();

const navbarOpen = ref(false);
const showSnackbar = ref(false);
const helpOpen = ref(false);
const changePasswordOpen = ref(false);
const searchProspect = ref(false);

const emit = defineEmits(["update"]);

const emitUpdate = () => {
    emit("update", navbarOpen.value);
};

watchEffect(() => {
    emitUpdate();
});

const auth = getAuth();
let snackbarText = "";

function snackbar(text: string) {
    showSnackbar.value = true;
    snackbarText = text;
}

const theme = useTheme();

const currentUserDb = await getCurrentUser();
const idTokenResult = auth.currentUser
    ? await getIdTokenResult(auth.currentUser)
    : null;

const isEmployee = computed(() => {
    return idTokenResult?.claims?.operator ? true : false;
});

const isAdminUser = computed(() => {
    return currentUserDb?.ClientId === "6X8mesClIltu3m4vtIae" ? true : false;
});

const isYouscaleUser = computed(() => {
    return currentUserDb?.ClientId === "MoUfzDUmvDXO0TXsizsJ" ? true : false;
});

async function logout() {
    try {
        await signOut(auth);
    } catch (err: unknown) {
        console.error("Logout error:", err);
    }
    theme.global.name.value = "light";
    localStorage.clear();
    router.push("/login");
}
</script>

<style scoped>
.v-navigation-drawer {
    font-size: 0.9rem;
    color: rgb(var(--v-theme-text)) !important;
    background-color: rgb(var(--v-theme-bar)) !important;
}

.list {
    flex: 1;
    overflow-y: scroll !important;
    display: flex;
    flex-direction: column;
}

.v-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    width: 200px;
    align-self: center;
}
</style>
