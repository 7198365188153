<template>
    <Dialog
        :title="$t('home.2024Numbers')"
        width="40vw"
        :closable="false"
        @close="emit('close')"
        :isDivider="false"
    >
        <template #text>
            <v-row>
                <v-col cols="4" class="d-flex flex-column align-center">
                    <Span class="number">10250</Span>
                    <Span class="mt-2">Calls passés</Span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column align-center">
                    <Span class="number">1758</Span>
                    <Span class="mt-2">K.0.</Span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column align-center">
                    <Span class="number">640</Span>
                    <Span class="mt-2">Rdv pris</Span>
                </v-col>
            </v-row>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { Span } from "@/components/customVuetify";
import Dialog from "@/components/customVuetify/Dialog.vue";

const emit = defineEmits(["close"]);
</script>

<style scoped>
.number {
    font-weight: bold;
    font-size: 44px;
}
</style>
